import './sass/style.scss';

import { headerChangeColor } from './types/headerChangeColor';
import { hoverChangeImage } from './types/hoverChangeImage';
import { spMenu, spMenuLinkClick } from './types/spMenu';

headerChangeColor()
if(window.innerWidth > 768){
    hoverChangeImage()
    console.log(window.outerWidth);
}
spMenu
spMenuLinkClick()