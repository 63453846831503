const hoverItems: any = document.getElementsByClassName("hoverItem"); 

const hoverChangeImage = () =>{
    for (let index = 0; index < hoverItems.length; index++) {
        const element = hoverItems[index].firstElementChild;
        element.addEventListener(
            "mouseover", (event: any) => {
                const imagePath:string = event.target.getAttribute("src")
                const hoverChangePath = imagePath.replace("default", "hover");
                event.target.setAttribute("src", hoverChangePath);
            },
        )
        
        element.addEventListener(
            "mouseleave", (event: any) => {
                const imagePath:string = event.target.getAttribute("src")
                const defaultPath = imagePath.replace("hover", "default");
                event.target.setAttribute("src", defaultPath);
            }
        )
    }
} 


export {hoverChangeImage}