const headerElement = document.getElementById("globalHeader");

const headerChangeColor = () =>{
    window.addEventListener("scroll", (event) => {
        if(window.scrollY  > 100){
            headerElement?.classList.add("l-header-wrapper--bg-white");
        }else{
            headerElement?.classList.remove("l-header-wrapper--bg-white");
        }

    })
} 


export {headerChangeColor}